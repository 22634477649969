.chromosome:hover {
  filter: invert();
}

.chromosome.selected {
  fill: rgba(255, 102, 0, 0.3) !important;
  stroke: red !important;
}

.separate {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke: red;
}

.freehandSelect {
  fill: rgba(255, 102, 0, 0.3);
  stroke-width: 2;
  stroke-linecap: round;
  stroke: red;
}
