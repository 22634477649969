.Karyogram {
  max-width: 1200px;
  display: grid;
  background-color: white;
  width: 100%;
}

.KaryogramRow {
  display: flex;

  &.separator {
    flex-grow: 1;
  }
}
